import * as React from "react";
import { Anchor, Heading, Paragraph } from "grommet";
import { ContentBlock } from "../../../components/content-block";

import CloudManagementImage from "../../../images/pages/cloud/cloud-management.png";
import CloudStrategyImage from "../../../images/pages/cloud/cloud-strategy.png";

const CloudPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#3600b2" }}>
        Cloud
      </Heading>
      <Paragraph fill>
        Através da <strong style={{color:"#3600b2"}}>computação em nuvem</strong>, podemos fornecer
        tecnologia de forma ágil com <strong style={{color:"#3600b2"}}>eficiência e baixo custo</strong>,
        os clientes pagam apenas pelos serviços utilizados e pelo tempo
        consumido, ajudando assim a reduzir os custos operacionais de TI,
        tirando partido da infraestrutura de forma mais eficiente e escalando o
        ambiente de acordo com as necessidades, sendo adaptáveis às mudanças e
        novas exigências de negócio. Independentemente da escolha da sua nuvem,
        nós gerimos completamente para que não se preocupe com nada.
      </Paragraph>

      <ContentBlock image={CloudStrategyImage}>
        <>
          <Heading level={3} style={{ color: "#3600b2" }}>
            Strategy
          </Heading>
          <Paragraph fill>
            <strong style={{color:"#3600b2"}}>Analisamos o cenário atual</strong> X (necessidades
            empresariais) alinhado com o plano estratégico da empresa e
            desenvolvemos um roadmap para a
            <strong style={{color:"#3600b2"}}>implementação e evolução do seu ambiente de cloud.</strong>
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock reverse image={CloudManagementImage}>
        <>
          <Heading level={3} style={{ color: "#3600b2" }}>
            Management
          </Heading>
          <Paragraph fill>
            Oferecemos consultoria especializada para{" "}
            <strong style={{color:"#3600b2"}}>definição de arquitetura</strong>, criação e/ou migração e{" "}
            <strong style={{color:"#3600b2"}}>integração de ambientes</strong> cloud e/ou cloud X
            bare-metal/on-premise, bem como o seu apoio,{" "}
            <strong style={{color:"#3600b2"}}>garantindo disponibilidade e desempenho</strong> do meio
            ambiente.
          </Paragraph>
        </>
      </ContentBlock>
    </>
  );
};

export default CloudPage;
